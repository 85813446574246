/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ActaAuthContext } from "pa_kit/auth";


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);
    
    const user = auth.user;
   
    const Login = lazy(() => import('services/login'));
    const App = lazy(() => import('./app'));
    const Dashboard = lazy(() => import('apps/dashboard/dashboard'));
    const SchedeProcedimentiList = lazy(() => import('apps/schede_procedimenti/components/schede_procedimenti_list'));
    const SchedaProcedimentoPage = lazy(() => import('apps/schede_procedimenti/components/scheda_procedimento_page'));
    const CreateScheda = lazy(() => import('apps/schede_procedimenti/components/create_scheda'));
    const EditScheda = lazy(() => import('apps/schede_procedimenti/components/edit_scheda'));
    const ActivitiesList = lazy(() => import('apps/activities/components/activities_list'));
    const ScadenzeList = lazy(() => import('apps/scadenze/components/scadenze_list'));
    const ScadenzaPage = lazy(() => import('apps/scadenze/components/scadenza_page'));
    const CreateScadenza = lazy(() => import('apps/scadenze/components/create_scadenza'));
    const EditScadenza = lazy(() => import('apps/scadenze/components/edit_scadenza'));
    const TextSectionPage = lazy(() => import('apps/text_sections/components/text_section_page'));
    const CreateTextSection = lazy(() => import('apps/text_sections/components/create_text_section'));
    const EditTextSection = lazy(() => import('apps/text_sections/components/edit_text_section'));
    const FilesArchive = lazy(() => import('apps/files/components/files_archive'));
    const MailsList = lazy(() => import('apps/mails/components/mails_list'));
    const CreateMail = lazy(() => import('apps/mails/components/create_mail'));
    const MailPage = lazy(() => import('apps/mails/components/mail_page'));
    const ForwardMail = lazy(() => import('apps/mails/components/forward_mail'));
    const SettingsPage = lazy(() => import('apps/settings/components/settings_page'));

    if(auth.isLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={user && user.is('procedure') ? <App /> : <Navigate to="/login" />} >
                        <Route path="" element={<Dashboard />} ></Route>
                        <Route path="registry" element={<SchedeProcedimentiList />} ></Route>
                        <Route path="activities" element={<ActivitiesList />} ></Route>
                        <Route path="create-scheda" element={<CreateScheda />} ></Route>
                        <Route path="registry/:idSchedaProcedimento" element={<SchedaProcedimentoPage />} ></Route>
                        <Route path="edit-scheda/:idSchedaProcedimento" element={<EditScheda />} ></Route>
                        <Route path="scadenze" element={<ScadenzeList />} ></Route>
                        <Route path="scadenze/:idScadenza" element={<ScadenzaPage />} ></Route>
                        <Route path="create-scadenza" element={<CreateScadenza />} ></Route>
                        <Route path="edit-scadenza/:idScadenza" element={<EditScadenza />} ></Route>
                        <Route path="sezioni/:section/" element={<TextSectionPage />} />
                        <Route path="create-text/:section/" element={<CreateTextSection />} />
                        <Route path="edit-text/:idTextSection/" element={<EditTextSection />} />
                        <Route path="archive" element={<FilesArchive />} ></Route>
                        <Route path="mail" element={<MailsList />} ></Route>
                        <Route path="create-mail" element={<CreateMail />} ></Route>
                        <Route path="mail/:idMail" element={<MailPage />} ></Route>
                        <Route path="forward-mail/:idMail" element={<ForwardMail />} ></Route>
                        <Route path="settings" element={<SettingsPage />} ></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;
